<template>
  <VExpansionPanels
    accordion
    flat
  >
    <VExpansionPanel>
      <VExpansionPanelHeader>{{ $t('grid_monitor.expression_calculations') }}(+ - * /)</VExpansionPanelHeader>
      <VExpansionPanelContent>
        <VRow
          class="mt-4"
          align-content="center"
        >
          <VCol
            cols="12"
          >
            <VTextField
              v-model="name"
              :label="$t('name')"
              clearable
              dense
            />
          </VCol>
          <VExpansionPanels>
            <VExpansionPanel
              v-for="(_, index) in mathExpression"
              :key="index"
            >
              <VExpansionPanelHeader>
                {{ `Factor ${index + 1}` }}
              </VExpansionPanelHeader>
              <VExpansionPanelContent>
                <VRow>
                  <VCol cols="12">
                    <span v-text="`${$t('grid_monitor.factor')} ${index + 1}`" />
                  </VCol>

                  <Fragment v-if="index > 0">
                    <VCol
                      cols="10"
                      offset="1"
                    >
                      <VSelect
                        v-model="mathExpression[index].connection"
                        :items="operators"
                        :label="`${$t('grid_monitor.operation_between_factor')} ${index} ${$t('and')} ${index + 1} `"
                        return-object
                        clearable
                        dense
                      />
                    </VCol>
                  </Fragment>

                  <VCol
                    cols="5"
                    offset="1"
                  >
                    <VTextField
                      v-model="mathExpression[index].first.const"
                      :disabled="!!mathExpression[index].first.exp"
                      type="number"
                      label="Const."
                      clearable
                      dense
                    />
                  </VCol>
                  <VCol cols="5">
                    <VSelect
                      v-model="mathExpression[index].first.exp"
                      :disabled="!!mathExpression[index].first.const"
                      :items="expressions"
                      label="Exp."
                      clearable
                      dense
                    />
                  </VCol>
                  <VCol
                    cols="10"
                    offset="1"
                  >
                    <VSelect
                      v-model="mathExpression[index].operation"
                      :items="operators"
                      :label="$t('grid_monitor.operation')"
                      return-object
                      clearable
                      dense
                      @click:clear="mathExpression[index].last = {}"
                    />
                  </VCol>
                  <VCol
                    cols="5"
                    offset="1"
                  >
                    <VSelect
                      v-model="mathExpression[index].last.exp"
                      :disabled="disableFactorItem(index, true)"
                      :items="expressions"
                      label="Exp."
                      clearable
                      dense
                    />
                  </VCol>
                  <VCol cols="5">
                    <VTextField
                      v-model="mathExpression[index].last.const"
                      :disabled="disableFactorItem(index)"
                      type="number"
                      label="Const."
                      clearable
                      dense
                    />
                  </VCol>
                </VRow>
              </VExpansionPanelContent>
            </VExpansionPanel>
          </VExpansionPanels>

          <VCol cols="12">
            <span v-text="formula" />
          </VCol>

          <VCol
            cols="4"
            sm="12"
          >
            <VBtn
              color="primary black--text"
              @click="mathExpression.push({
                first: {},
                last: {}
              })"
            >
              <span class="mr-2">{{ $t('add') }}</span>
              <VIcon dark>
                mdi-plus-box
              </VIcon>
            </VBtn>
          </VCol>

          <VCol
            cols="4"
            sm="12"
          >
            <VBtn
              color="primary black--text"
              :disabled="mathExpression.length < 2"
              @click="mathExpression.pop()"
            >
              <span class="mr-2">{{ $t('remove') }}</span>
              <VIcon dark>
                mdi-minus-box
              </VIcon>
            </VBtn>
          </VCol>

          <VCol
            cols="4"
            sm="12"
          >
            <VBtn
              color="primary black--text"
              :disabled="disabledCreateVariable"
              @click="updatePlotData"
            >
              <span class="mr-2">{{ $t('create') }}</span>
              <VIcon dark>
                mdi-chevron-right-box
              </VIcon>
            </VBtn>
          </VCol>
        </VRow>
      </VExpansionPanelContent>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<script>
import { mapState } from 'vuex'
import { Fragment } from 'vue-frag'
import { isArray } from 'lodash'

export default {
  name: 'MSMathExpressions',

  components: {
    Fragment
  },

  props: {
    variables: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    name: null,
    mathExpressions: [],
    mathOperator: null,
    constant: null,
    mathExpression: [{
      first: {},
      last: {}
    }]
  }),

  computed: {
    ...mapState({
      plotData: ({ measurements }) => measurements.plotData
    }),

    operators () {
      return [
        { text: `${this.$t('grid_monitor.addition')} (+)`, value: 'addition', symbol: '+' },
        { text: `${this.$t('grid_monitor.subtraction')} (-)`, value: 'subtraction', symbol: '-' },
        { text: `${this.$t('grid_monitor.multiplication')} (*)`, value: 'multiplication', symbol: '*' },
        { text: `${this.$t('grid_monitor.division')} (/)`, value: 'division', symbol: '/' }
      ]
    },

    expressions () {
      return this.variables.map(variable => variable.expression)
    },

    formula () {
      return this.mathExpression
        .map(({ first, last, operation, connection }) => {
          const factor = [
            first.const || first.exp,
            operation?.symbol,
            last.const || last.exp
          ].filter(Boolean).join(' ')

          return factor.length
            ? `${connection?.symbol || ''} (${factor})`
            : ''
        })
        .join(' ').trim()
    },

    values () {
      let result
      const KEYS = ['first', 'last']

      const valuesExpressions = this.mathExpression.map((expression, index) => {
        const [first, last] = this.getOperatorValue({
          keys: KEYS,
          expression,
          variables: this.variables
        })

        const { lastException, operatorException } = this.addLastException({
          first,
          last,
          factorIndex: index,
          expression
        })

        return this.evalValues(first, lastException, operatorException)
      })

      valuesExpressions.reduce((acc, next, index) => {
        const connection = this.mathExpression[index]?.connection?.symbol
        result = this.evalValues(acc || result, next, connection)

        return valuesExpressions[index + 2]
      }, [])

      return result
    },

    disabledCreateVariable () {
      const isArrayValue = isArray(this.values)

      const conditions = [
        !this.name,
        !isArrayValue && !this.values.length,
        !isArrayValue && isNaN(this.values),
        isArrayValue && this.values.some(value => isNaN(value)),
        this.mathExpression
          .find((expression, index) => index && !expression.connection)
      ]

      return conditions.some(Boolean)
    }
  },

  methods: {
    evalValues  (first, last, symbol) {
      if (!symbol) return last

      const isArrayFirst = isArray(first)
      const isArrayLast = isArray(last)
      const someArray = isArrayFirst || isArrayLast
      const everyArray = isArrayFirst && isArrayLast

      if (!someArray && this.mustReturnNull(first, last, symbol)) return null
      if (!someArray) return eval(`${first} ${symbol} ${last}`)

      const hasFirst = first.length
      const hasLast = last.length

      const runEveryArrayFlow = () => {
        if (hasFirst && hasLast) {
          return first
            .map((_, index) => {
              const mustReturnNull = this.mustReturnNull(
                first[index],
                last[index],
                symbol
              )

              return mustReturnNull
                ? null
                : eval(`${first[index]} ${symbol} ${last[index]}`)
            })
        }

        return !hasFirst ? last : first
      }

      const runArrayFirstFlow = () => {
        const arrValues = first
          .map((_, index) => eval(`${first[index]} ${symbol} ${last}`))

        return hasFirst
          ? arrValues
          : eval(`${0} ${symbol} ${last}`)
      }

      const runArrayLastFlow = () => {
        const arrValues = last
          .map((_, index) => eval(`${first} ${symbol} ${last[index]}`))

        return hasLast
          ? arrValues
          : eval(`${first} ${symbol} ${0}`)
      }

      const options = {
        everyArray: everyArray && runEveryArrayFlow,
        isArrayFirst: isArrayFirst && runArrayFirstFlow,
        default: runArrayLastFlow
      }

      const functionFlow = Object.values(options).find(Boolean)

      return functionFlow()
    },

    mustReturnNull (first, last, symbol) {
      return ((last === null || first === null) || (symbol === '/' && last === 0))
    },

    disableFactorItem (index, isExp) {
      if (isExp) {
        return !!this.mathExpression[index].last.const ||
          (!this.mathExpression[index].first.const && !this.mathExpression[index].first.exp) ||
          !this.mathExpression[index].operation
      }

      return !!this.mathExpression[index].last.exp ||
        (!this.mathExpression[index].first.const && !this.mathExpression[index].first.exp) ||
        !this.mathExpression[index].operation
    },

    updatePlotData () {
      const { first, last } = this.mathExpression.find(
        expression => expression.first.exp || expression.last.exp
      )

      const expression = first.exp || last.exp

      const currentVariable = this.variables.find(variable =>
        variable.expression === expression)

      this.$store.dispatch('setElement', {
        path: 'measurements.plotData',
        value: [
          ...this.plotData,
          {
            aggregation: this.name || this.setVariableName(),
            data: {
              time: currentVariable?.plot?.data?.time,
              value: this.values
            },
            meters: [],
            origin: 'mathExpression',
            tab: this.variables.at(0).plot.tab
          }
        ]
      })

      this.mathExpression = [{
        first: {},
        last: {}
      }]
      this.name = null
    },

    setVariableName () {
      const setNomenclature = (first) => {
        return first.join(`-${this.mathOperator}-`).toUpperCase()
      }

      return !this.constant
        ? setNomenclature(this.filteredMathExpressions)
        : setNomenclature([...this.filteredMathExpressions, this.constant])
    },

    setDisabledState (dynamicValue) {
      return ![null, '', undefined].includes(dynamicValue)
    },

    addLastException ({ first, last, factorIndex, expression = {} }) {
      const multiOperator = this.operators
        .find(operator => operator.value === 'multiplication') || {}

      return first && isNaN(last) && !isArray(last) && factorIndex > 0
        ? {
          lastException: 1,
          operatorException: multiOperator.symbol
        }
        : {
          lastException: last,
          operatorException: expression.operation?.symbol
        }
    },

    getOperatorValue ({ keys = [], expression = {}, variables = [] }) {
      return keys.map(key => {
        const rawValue = Object
          .values(expression[key])
          .filter(Boolean)
          .at(0)
        
        const values = variables
          .find(variable => variable.expression === rawValue)
          ?.plot.data.value

        return values || parseFloat(rawValue)
      })
    }
  }
}
</script>

<style scoped>
  .plot-position {
    font-size: 1.5rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    appearance: none;
  }
</style>
